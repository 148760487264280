.col_left {
    margin-bottom: 30px
}

.col_right div:global(.builder-component) {
    width: 100%;
}

.col_middle {
    display: none;
}

@media (min-width: 768px) {
    .login {
        width: 330px;
    }

    .row {
        margin: 80px 0;
    }
    
    .col_left {
        display: flex;
        justify-content: flex-end;
        padding: 0;
    }
    
    .col_middle {
        display: flex;
        justify-content: center;
        padding: 0;
    }
    
    .col_right {
        display: flex;
        justify-content: flex-start;
        padding: 0;
    }
    
    .line {
        height: 100%;
        width: 1px;
        background-color: var(--color-grey);
    }
    
}