.root {
    display: flex;
    flex-direction: column;
    border-top: solid 1px var(--color-grey-medium);
}

.payoffs {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    border-top: solid 1px var(--color-grey-medium);
}

.content {
    display: flex;
    padding: 6px 0px;
    background-color: #72082e;
    color: #fff;
}

.content h4 {
    font-size: 1.5rem;
    /*line-height: 1.5rem;*/
    margin-top: 0;
    margin-bottom: 8px;
}

.content ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.content ul a {
    text-decoration: none;
    color: #fff;
    line-height: 2.3rem;
}

.newsletterinput {
    margin: 15px 0 0 0;
}

.socials {
    margin: 22px 0 0 0;
}

.socials a {
    margin-right: 10px;
}

.payment {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    border-bottom: solid 1px var(--color-grey-medium);
}

.metelinks span {
    display: inline-block;
    margin: 0 7px;
}

.metelinks {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.metelinks {
    text-decoration: none;
}

.copyright {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.widget {
    display: flex;
    justify-content: center;
    height: 24px;
}

.top_row {
    display: flex;
    order: -1;
}

.seperator_row {
    display: flex;
}

.bottom_row {
    display: flex;
}

.accordion .links {
    border-bottom: solid 1px #843350;
    padding-bottom: 10px;
}

.accordion .header {
    padding: 6px 0;
}

.accordion .header h4 {
    margin: 0;
}

.accordion .list {
    padding: 10px 0;
}

.newsletter {
    border-bottom: solid 1px #843350;
}

.usps {
    padding: 20px 0;
}

.footer_link_title {
    font-size: 1.5rem;
    line-height: 1.42857142;
    margin: 0;
    font-weight: bold;
}

@media (min-width: 768px) {

    .accordion {
        padding: 10px 0 0 0;
        border-top: 2px solid #843350;
    }

    .accordion .links {
        border-bottom: none;
        padding-bottom: 0;
    }

    .accordion .header {
        padding: 8px 0;
    }

    .accordion .list {
        padding: 0;
    }

    .newsletter {
        border-bottom: unset;
    }
}