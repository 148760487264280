.h1 {
    color: #72082e;
    margin-bottom: 0;
}

.input { 
    margin-bottom: 30px;
}

.button { 
    width: 100%;
}

.form {
    max-width: 500px;
    margin-bottom: 30px;
}

.error_message {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    background-color: #FFE5E5;
    border: solid 1px #E50000;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.password_requirements {
    display: flex;
    flex-direction: column;
    margin: 5px 0 10px;
    padding: 20px;
    max-width: 500px;
}

.success_message {
    max-width: 500px;
    margin: 5px 0 10px;
    border: none;
}