.center {
    text-align: center;
}

.wishlist_product {
    text-align: left;
}

.message {
    text-transform: none; 
 }