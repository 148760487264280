.input {
    margin-bottom: 30px;
}

.input ~ label abbr {
    display: none;
}

.button {
    width: 100%;
}

.password_container {
    position: relative;
}

.forgot_password {
    position: absolute;
    top: 0;
    right: 0;
}

.error_message {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    background-color: #FFE5E5;
    border: solid 1px #E50000;
    padding: 20px;
    border-radius: 10px;
}

.error_message a {
    margin-top: 15px;
}
