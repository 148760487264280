.toolbar {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    padding: 15px 0;
    margin-bottom: 24px;
    border-bottom: 1px solid var(--color-grey-medium);
}

.toolbar_left,
.toolbar_right {
    display: inline-flex;
    width: 100%;
}

.sort {
    width: 50%;
}

.toggle_filter {
    width: 50%;
    height: 40px;
    margin-right: 10px;
}

.stats {
    display: inline-block;
    margin-bottom: 10px;
}

.pagination {
    height: 65px;
    padding: 15px 0;
    margin-top: 24px;
    border-top: 1px solid var(--color-grey-medium);
}

.desc {
    margin-bottom: 15px;
}

.categories_list ul {
    list-style-type: none;
    padding: 0;
}

.categories_list ul li {
    list-style-type: none;
    position: relative;
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.categories_list ul li:before {
    content: "";
    display: block;
    box-sizing: border-box;
    width: 6px;
    height: 6px;
    border-bottom: 2px solid;
    border-left: 2px solid;
    transform: rotate(45deg);
}

.categories_list ul li a {
    text-decoration: none;
    color: var(--button-secondary-color);
}

.categories_list ul li a span:last-child {
    display: none;
}

/* HierarchicalMenu */ 

.categories_title {
    font-size: 1.5rem;
    font-weight: 800;
}

:global(.ais-HierarchicalMenu-item--selected) a  {
    font-weight: 600;
}

:global(.ais-HierarchicalMenu-list) {
    list-style: none;
    margin: 0;
}

:global(.ais-HierarchicalMenu-item--selected > .ais-HierarchicalMenu-link > .ais-HierarchicalMenu-label) {
    font-weight: 600;
}

:global(.ais-HierarchicalMenu-list li:before) {
    position: relative;
    content: "";
    display: inline-block;
    width: 7px;
    height: 7px;
    border-right: 0.2em solid var(--button-secondary-color);
    border-top: 0.2em solid var(--button-secondary-color);
	transform: rotate(-135deg);
    margin-right: 1rem;
}

:global(.ais-HierarchicalMenu-label) {
    font-weight: 400;
    color: var(--button-secondary-color);
}

:global(.ais-HierarchicalMenu-count) {
    color: rgb(35, 31, 32);
    font-size: 13px;
    font-weight: 400;
}

:global(.ais-HierarchicalMenu-count)::before {
    content: "(";
}

:global(.ais-HierarchicalMenu-count)::after {
    content: ")";
}

:global(.ais-HierarchicalMenu-link) {
    text-decoration: none;
    margin: 2rem 0;
}

:global(.ais-HierarchicalMenu-item) {
    margin: 1rem 0;
}

@media (min-width: 768px) {

    .category {
        min-height: 0;
    }

    .toolbar {
        flex-direction: row;
    }

    .toolbar_left,
    .toolbar_right {
        width: auto;
    }

    .sort {
        width: auto;
    }

    .stats {
        margin-bottom: 0;
    }
}
