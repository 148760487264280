.root {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.topmenu {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    background-color: #72082e;
    color: #fff;
}

.topmenu_container {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.hamburger {
    display: flex;
    align-items: center;
}

.logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.logo img {
    height: 28px;
    width: auto;
}

.search {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: 0 20px
}

.minimized .menu {
    justify-content: space-between;
    width: 100%;
}

.menu {
    display: flex;
    align-items: center;
}

.iconlink {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    color: var(--color-grey-light);
    text-decoration: none;
    font-size: 1.3rem;
    line-height: 2rem;
    margin-right: 12px;
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
}

.iconlink:active {
    color: var(--color-grey-light);
}

.iconlink_label {
    display: none;
}

.iconlink:last-child {
    margin-right: 0px;
}

.iconlink_quantity {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    top: -10px;
    right: 12px;
    background-color: rgb(255, 153, 0);
    width: 16px;
    height: 16px;
    font-size: 0.9rem;
    line-height: 16px;
    border-radius: 16px;
}

.bottommenu {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-grey-light);
    height: 1px;
    min-height: 54px;
    position: relative;

    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
}

.bottommenu .search {
    padding: 0 12px
}


@media (min-width: 768px) {

    .root {
        position: sticky;
    }

    .logo {
        position: unset;
        left: unset;
        top: unset;
        transform: unset;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logo img {
        height: 49px;
    }

    .iconlink {
        margin-right: 35px;
    }


    .topmenu {
        height: 79px;
    }

}

@media (min-width: 1024px) {

    .search {
        padding: 0 50px
    }

    .iconlink {
        margin-right: 22px;
    }
}

@media (min-width: 1440px) {
    .iconlink_label {
        display: inline;
    }

    .iconlink {
        margin-right: 37px;
    }
}


@media (max-width: 768px) {
    .menu_actions {
        float: right;
        margin-left: auto;
    }

    .minimized .menu {
        justify-content: center;
    }
    .wishlist_not_filled_icon {
        width: 2rem!important;
    }
}