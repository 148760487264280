
.root {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
}

.clickable {
   cursor: pointer;
}

.root svg {
    width: 100%;
    height: 100%;
}