.root {
    position: relative;
}

.separator {
    height: 1px;
    padding: 0;
    margin: 20px 0;
    border: none;
    background: var(--color-grey-medium);
}

.row {
    display: flex;
    align-items: center;
    padding: 5px 0;
}

.amount {
    width: 130px;
    margin-left: auto;
    text-align: right;
}

.discount,
.coupon {
    font-weight: 800;
}

.discount:before,
.coupon:before {
    content: "-";
    margin: 0 .5rem;
    display: inline-block;
}

.amount_bold {
    font-weight: 600;
}

.total {
    font-weight: 800;
}

.label {
    color: var(--color-grey);
}

.total_green {
    color: #65B32E;
}