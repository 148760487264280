.root {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    padding: 24px;
}

.message {
    padding: 24px;
    border: 1px solid #eee;
}

.message h1 {
    margin-top: 0;
}

.message .error {
    margin: 15px 0;
    padding: 10px 15px;
    background: #f5f5f5;
}