.root {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 47px;
}

.root img {
    width: auto;
    margin-right: 10px;
}

.root img:last-child {
    margin-right: 0px;
}

@media (min-width: 768px) {
    .root img {
        margin-right: 20px;
    }
}