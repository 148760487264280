.h1 {
    color: #72082e;
    margin-bottom: 0;
}

.input { 
    margin-bottom: 30px;
}

.button { 
    width: 100%;
}

.form {
    max-width: 500px;
    margin-bottom: 30px;
}