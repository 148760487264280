.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.separator {
    height: 1px;
    padding: 0;
    margin: 20px 0;
    border: none;
    background: var(--color-grey-medium);
}

.edit {
    display: inline-block;
    margin-top: 10px;
    color: #FFA50A;
    text-decoration: none;
}

.next {
    margin-top: 30px;
}