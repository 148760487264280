.orders {
    margin-top: 12px;
}

.search {
    margin-bottom: 32px;
    background-color: white;
}

.message {
   text-transform: none; 
}