.form {
    background: var(--color-grey-light);
    border-radius: 10px;
    padding: 13px 38px; 
    margin-bottom: 10px;
}

.form:last-child {
    margin-bottom: 40px;
}

.header {
    color: #73082F;
    margin: 0;
}

.input {
    background-color: #ffffff;
}

.legal_notice {
    font-size: 0.9rem;
    padding-top: 0;
}

.info {
    font-size: 1.2rem;
    margin-top:4px;
}

.error_msg {
    margin-top: .5rem;
    color: #FF595C;
}