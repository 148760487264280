.root {
    padding: 20px 35px 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 3px;
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: column;
    gap: 3px;
    background-color: var(--color-secondary);
}

.track_root {
    display: flex;
    justify-content: flex-end;
}

.track {
    background-color: #f9f9f6;
    padding: 20px 35px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: column;
    gap: 7px;
    width: 95%;
    font-size: 12px;
}

.track_title {
    font-weight: 700;
    display: block;
}

.container {
    margin-bottom: 25px;
}

.number_date {
    display: flex;
    justify-content: space-between;
}

.order_number {
    font-weight: bold;
}

.pending {
    color: #ffad21;
}

.sent {
    color: #84c258;
}

.delivered {
    color: #822245;
}

.track_code {
    color: #fcb050;
}

.italic {
    font-style: italic;
    cursor: pointer;
    
}

.icon {
    align-self: end;
    cursor: pointer;
}

@media (max-width: 768px) {
    .root,
    .track {
        padding: 10px 15px;
    }

    .number_date {
        flex-direction: column;
    }
}