.heading {
    margin-top: 20px;
}

.heading h1 {
    color: var(--color-primary);
    margin: 0;
    text-transform: none;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 212px;
}

.gallery {
    position: relative;
}

.anchors {
    margin-bottom: 30px;
}

.average_hidden {
    visibility: hidden;
}

.usps {
    display: inline-flex;
    flex-direction: column;
    color: #73082F;
    font-weight: 800;
    margin-bottom: 30px;
}

.usp {
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;
}

.usp:last-child {
    margin-bottom: 0px;
}

.usps .icon {
    margin-right: 12px;
}

.description {
    max-width: 700px;
    margin-bottom: 30px;
}

@media (min-width: 640px) {}

@media (min-width: 768px) {
    .heading {
        margin: 0;
    }
}