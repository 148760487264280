.pricing {
    display: flex;
    white-space: nowrap;
}

.modal {
    align-items: center;
}

.price {
    width: 50%;
}

.price_ex {
    font-size: 30px;
    font-weight: 800;
}

.price_from_wrapper {
    display: flex;
    align-items: center;
}

.price_from_label {
    margin-right: 10px;
}

.price_from {
    font-size: 18px;
    text-decoration: line-through;
}

.price_volume {
    width: 50%;
}

.price_volume ul {
    margin: 5px 0 0 0;
    padding: 0;
    list-style: none;
    border-top: 1px solid var(--color-grey-medium);
}

.price_volume li {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    border-bottom: 1px solid var(--color-grey-medium);
}

.label {
    font-weight: 800;
}

.smaller_font {
    font-size: 1.3rem;
}

.small_font {
    font-size: 2rem;
}