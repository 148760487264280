.h1 {
    color: #72082e;
}

.input { 
    margin-bottom: 30px;
}

.button { 
    width: 100%;
}

.form {
    max-width: 500px;
    margin-bottom: 30px;
}

.container {
    width: 400px;
    margin: 20px 0px 40px 0px;
}

.center {
    display: flex;
    justify-content: center;
}

.button {
    width: 100%;
}