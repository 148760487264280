.root {
    display: flex;
    align-items: center;
    height: 40px;
}

.progress {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 9px;
    color: #eeeeec;
    border-radius:50px;
}

.progress::-moz-progress-bar {
    background: #FFA50A;
    border-radius: 10px;
}

.progress::-webkit-progress-value {
    background: #FFA50A;
    transition: width 1s;
    border-radius: 10px;
}

.progress::-webkit-progress-bar {
    background: #eeeeec;
}
