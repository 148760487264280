.root {
    
}

.content {
    display: flex;
    align-items: center;
    gap: 15px;
}

.content span {
    white-space: nowrap;
}
