.root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    background-color: #72082e;
    color: #fff;
    font-family: 'Hind', sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
}

.items {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior-y: contain;
}

.close {
    position: absolute;
    right: 10px
}

.seperator {
    display: flex;
    height: 1px;
    background-color: var(--color-grey-medium);
    margin: 0 30px;
}

.meta {
    flex-direction: column;
    padding: 10px 0px;
}

.meta a {
    margin: 0;
    padding: 10px 30px;
    font-weight: normal;
}