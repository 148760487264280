.root {
    display: flex;
    padding: 0;
    overflow: hidden;
    position: relative;
    width: 152px;
    height: 42px;
    border: 1px solid var(--color-grey-medium);
    border-radius: 10px;
    transition: none;
}

.root.open {
    width: 152px;
}

.input {
    margin: 0;
    padding:  0 8px;
    height: 40px;
    width: 70px;
    border: none;
    border-left: 1px solid var(--color-grey-medium);
    border-right: 1px solid var(--color-grey-medium);
    border-radius: 0;
    font-size: 1.4rem;
    line-height: 1.68rem;
    color: #333333;
    text-align: center;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: none;
}

.input:focus {
    border: 1px solid #B4B4B4;
    border-top: none;
    border-bottom: none;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input[type=number] {
    appearance: textfield;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-primary);
    background-color: #fff;
    font-size: 2rem;
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 3px;
    border: 0;
    cursor: pointer;
}

.button:last-child {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
}

.button_disabled {
    color: var(--color-grey-medium);
    pointer-events: none;
}

.disabled {
    background: field;
}
