.root {
    display: flex;
    align-items: center;
}

.stars,
.reviews {
    position: relative;
    white-space: nowrap;
}

.stars:before,
.reviews:before {
    content: attr(data-label);
    visibility: hidden;
}

.reviews > span,
.stars > span {
    position: absolute;
    left: 0;
    top: 0;
}

.progress {
    flex: 1;
    margin: 0 20px;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 12px;
    color: #FFF;
}

.progress::-moz-progress-bar {
    background: #FFA50A;
    border-radius: 10px;
}

.progress::-webkit-progress-value {
    background: #FFA50A;
    transition: width 1s;
    border-radius: 10px;
}

.progress::-webkit-progress-bar {
    background: #FFF;
    border: 1px solid #8F8D8C;
    border-radius: 10px;
}

.progress.progress_stars_1::-moz-progress-bar {
    background: var(--trustpilot-one-star);
}
.progress.progress_stars_1::-webkit-progress-value {
    background: var(--trustpilot-one-star);
}

.progress.progress_stars_2::-moz-progress-bar {
    background: var(--trustpilot-two-star);
}
.progress.progress_stars_2::-webkit-progress-value {
    background: var(--trustpilot-two-star);
}

.progress.progress_stars_3::-moz-progress-bar {
    background: var(--trustpilot-three-star);
}
.progress.progress_stars_3::-webkit-progress-value {
    background: var(--trustpilot-three-star);
}

.progress.progress_stars_4::-moz-progress-bar {
    background: var(--trustpilot-four-star);
}
.progress.progress_stars_4::-webkit-progress-value {
    background: var(--trustpilot-four-star);
}

.progress.progress_stars_5::-moz-progress-bar {
    background: var(--trustpilot-five-star);
}
.progress.progress_stars_5::-webkit-progress-value {
    background: var(--trustpilot-five-star);
}

@media (max-width: 768px) {
    .reviews {
        margin-right: 10px;
    }
}
