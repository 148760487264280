.top_bar {
    background: var(--color-grey-light);
    margin-bottom: 45px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
}

.cart {
    position: relative;
    padding: 25px 12px;
    margin-left: -12px;
    margin-right: -12px;
    background: var(--color-grey-light);
}

.cart h4 {
    margin: 0;
}

@media (min-width: 768px) {
    .cart {
        padding: 25px 15px;
        margin-left: 0;
        margin-right: 0;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }
}

@media (min-width: 1024px) {
    .cart {
        padding: 50px;
    }
}