.root {
    position: relative;
    width: 100%;
}

.search_input {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #231F20;
    border-radius: 10px;
    border-color: transparent;
    background-color: var(--color-grey-light);
    border: 1px var(--color-grey-medium) solid;
    margin: 0;
    padding: 0px 15px;
    background: #ffffff;
}

.search_input_open {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
}

.search_input input {
    box-shadow: none;
    appearance: none;
    outline: none;
    width: 100%;
    border: none;
    height: 40px;
    margin-right: 15px;
    background: #ffffff;
}

.search_input button {
    display: inline-flex;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.search_input button.clear {
    margin-right: 15px;
}

.results {
    position: absolute;
    z-index: 1000;
    width: 100%;
    min-height: 0px;
    background-color: #fff;
    padding: 20px;
    color: #231F20;
    border: 1px var(--color-grey-medium) solid;
    border-top: none;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
    overflow: hidden;
}

.results .source {
    margin-bottom: 20px;
}

.results .source:last-child {
    margin-bottom: 0;
}

.result_item mark {
    font-weight: 800;
}