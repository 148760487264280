.btn {
    color: #000;
    background-color: #f3f3f3;
    font-weight: 800;
    text-transform: uppercase;
    padding: 6px 6px;
    position: relative;
}
.btn:hover {
    background-color: #f7f7f7;
}

@media (min-width: 1440px) {
    .btn:hover,
    .btn {
        background-color: transparent;
    }
    
    .next {
        left: 20px;
    }
    
    .prev {
        right: 20px;
    }
}