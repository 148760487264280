.root {
    color: #231F20;
}

.menu,
.back_menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    padding: 10px 0 10px 0;
    z-index: 152;
}

.menu > li .link,
.back_menu > li > a {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 30px;
    text-decoration: none;
    color: #231F20;
    font-size: 1.3rem;
    border-bottom: 1px #ffffff solid;
}

.menu > li:hover .link {
    background-color: var(--color-grey-light);
    border-bottom: 1px var(--color-grey-medium) solid;
}

.menu > li.forward > .link {
    font-size: 1.4rem;
    line-height: 1.9rem;
    font-weight: 800;
    color: #231F20;
}

.menu > li.forward > .link::after {
    content: url("data:image/svg+xml; utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.922%22%20height%3D%227.971%22%20viewBox%3D%220%200%204.922%207.971%22%3E%3Cpath%20d%3D%22M4.922.937%2C3.986%2C0%2C0%2C3.986%2C3.986%2C7.971l.937-.937L1.88%2C3.986Z%22%20transform%3D%22translate%284.922%207.971%29%20rotate%28180%29%22%20fill%3D%22%23231f20%22%2F%3E%3C%2Fsvg%3E%0A");
    display: flex;
    width: 5px;
    height: 100%;
}

.back_menu { padding: 10px 0 0 0; }

.back_menu > li > a {
    text-decoration: none;
    justify-content: unset;
    padding: 5px 30px;
}

.back_menu > li > a::before {
    content: url("data:image/svg+xml; utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.922%22%20height%3D%227.971%22%20viewBox%3D%220%200%204.922%207.971%22%3E%3Cpath%20d%3D%22M4.922.937%2C3.986%2C0%2C0%2C3.986%2C3.986%2C7.971l.937-.937L1.88%2C3.986Z%22%20fill%3D%22%23231f20%22%2F%3E%3C%2Fsvg%3E%0A");
    margin-right: 14px;
}
