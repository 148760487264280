.root {
    margin: 20px 0;
    padding: 0;
}

.error {
    color: #E3002B;
    font-weight: bold;
    margin-top: 15px;
}

.toggle {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.button {
    width: 100%;
}

.toggle_row {
    margin: 0;
}

.toggle_row > * {
    padding: 0;
}

.form_row {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.form_row.active {
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
}

.form_row.no_coupon_state {
    max-height: 22px;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}