.notification {
    padding: 25px 12px;
    background: #fff9ee;
}

.items {
    margin: 30px 0;
}

.totals {
    position: relative;
    padding: 25px 12px;
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: 30px;
    background: var(--color-grey-light);
}

.totals h4 {
    margin: 0;
}

.button {
    width: 100%;
    margin-bottom: 30px;
}

.payment {
    margin-bottom: 30px;
}

.usp {
    margin-bottom: 30px;
}

.widget {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin-bottom: 10px;
}

@media (min-width: 768px) {
    .notification {
        padding: 25px 40px;
    }

    .totals {
        padding: 25px 15px;
        margin-left: 0;
        margin-right: 0;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }
    
    .freq_bought {
        border-top: 1px solid var(--color-grey-medium);;
    }
}

@media (min-width: 1024px) {
    .totals {
        padding: 50px;
    }
}