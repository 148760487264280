.root {
    position: relative;
    padding: 25px 12px;
    margin-left: -12px;
    margin-right: -12px;
    background: var(--color-grey-light);
}

.separator {
    height: 1px;
    padding: 0;
    margin: 20px 0;
    border: none;
    background: var(--color-grey);
}

.row {
    margin-bottom: 20px;
}

.smallmargin {
    margin-bottom: 10px;
}

.row .label {
    display: inline-block;
    margin-right: 20px;
    width: 100%;
    max-width: 15rem;
}

.label {
    font-weight: 800;
}

.unit {}

.atc_label {
    width: 100%;
    max-width: 15rem;
}

.status {
    margin-top: 20px;
}

.stock {
    display: flex;
    align-items: center;
}

.in {
    color: #65B32E;
    font-weight: 800;
}

.stock span {
    margin-right: 10px;
}

.delivery span {
    color: #65B32E;
    font-weight: 800;
}

.wishlistbutton {
    border-radius: 10px;
    padding: 10px 30px;
    height: 44px;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #000;
}

.widget {
  text-align: left !important;
}

@media (min-width: 768px) {
    .root {
        height: 100%;
        padding: 25px 15px;
        margin-left: 0;
        margin-right: 0;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }
}

@media (min-width: 1024px) {
    .root {
        padding: 40px;
    }

    .smaller_padding {
        padding: 15px 40px 40px;
    }

    .sticky {
        position: sticky;
        top: 180px;
    }

}

@media (max-width: 768px) {
    .atc_label {
        max-width: 10rem;
    }
}