.root {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    max-width: 694px;
}

.largeimage {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    border: solid 2px #f7f7f7;
}

.viewport {
    display: flex;
    width: 100%;
    overflow: hidden;
}

.button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    padding: 10px;
    margin: 0;
    border: none;
    background: none;
    cursor: pointer;

    top: 50%;
    transform: translate(0, -50%);
}

.prev {
    left: 0;
}

.next {
    right: 0;
}

.images {
    display: flex;
    flex: 0 0 100%;
    transition: all cubic-bezier(.795, -.035, 0, 1) .5s;
}

.image, .bbw {
    flex: 0 0 100%;
    max-width: 100%;
    max-width: 460px;
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.youtube_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background: rgba(255, 255, 255, 0.4);
}

.youtube_overlay path {
    -webkit-filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, .2));
    filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, .2));
}

.video_image {
    display: block;
    width: 100%;
}

.video_image.video_active {
    display: none;
}

.video {
    display: none;
}

.video_active+.video {
    display: block;
}

.thumbnails {
    display: flex;
    margin-top: 30px;
}

.thumbnail {
    position: relative;
    width: 105px;
    cursor: pointer;
    margin-left: 15px;
}

.thumbnail:first-child {
    margin-left: 0px;
}

.thumbnails_more {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    font-family: 'Hind';
    font-size: 2.8rem;
    font-weight: 800;
}