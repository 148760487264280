.root {
    padding: 12px;
    background: #FFF;
    max-width: calc(100% - 24px);
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.55);
}

.root h4 {
    display: flex;
    align-items: center;
    margin: 0 0 20px 0;
}

.root .form {
    display: flex;
    flex-direction: column;
}

.qty {
    display: flex;
    align-items: center;
    gap: 10px;
}

.image {
    flex: 0 0 100px;
    width: 100px;
    height: 100px;
}

.product {
    display: flex;
}

.details {
    max-width: 400px;
    padding: 0 12px;
}

.buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 12px 0 0 0;
}

.buttons button {
    width: 100%;
    margin-bottom: 12px;
}

.buttons button:last-child {
    margin-bottom: 0;
}

.buttons_form {
    display: flex;
}

.icon {
    margin-right: 10px;
}

.name {
    color: #73082F;
    font-size: 1.4rem;
    font-weight: 800;
    margin-bottom: 10px;
}

.label {
    display: inline-block;
    margin-right: 10px;
    font-weight: 800;
}

.error {
    color: #E50000;
    width: 100%;
    max-width: 50rem;
    text-align: left;
}

.row {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.row .label_info {
    display: inline-block;
    max-width: 15rem;
}

.label_info {
    font-weight: 800;
}

.package_unit::first-letter {
    text-transform: capitalize;
}

.package_unit {
    text-transform: lowercase;
}

@media (min-width: 768px) {
    .root {
        padding: 24px;
        max-width: calc(100% - 48px);
    }

    .root .form {
        flex-direction: row;
    }

    .details {
        padding: 0 30px;
    }

    .buttons {
        width: 220px;
        margin: 0;
    }

    .buttons button {
        margin-bottom: 24px;
    }
}

@media (max-width: 769px) {
    .buttons_form {
        flex-direction: column;
    }
}