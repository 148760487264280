
.root {
    position: relative;
    overflow: hidden;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 0;

    width: 100%;
    min-height: 20px;
}

.fade {
    display: flex;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    width: 100%;
    height: 50px;
    opacity: 1;
}

.fade_open { 
    opacity: 0;
    height: 0px;
 }

.reademore {
    display: flex;
    width: 100%;
    background: #ffffff;
    padding: 5px 0;
    cursor: pointer;
    color: var(--color-primary);
}
