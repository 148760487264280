.root {
    display: flex;
    align-items: center;
    height: 97px;
    width: 100%;
    background: #FFF;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    padding: 0px 37px;
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 20px;
    cursor: pointer;
}

.ckechbox {
    margin-right: 50px;
}

.icon {
    margin-right: 10px;
}