.root {
    display: flex;
    flex-direction: column;
}

.heading {
    font-size: 1.5rem;
    font-weight: 800;
}

.values {
    display: flex;
    align-items: center;
    margin: 15px 0 0 0;
}

.no_values {
    margin: 15px 0 0 0;
    color: var(--color-grey-medium);
}

.separator {
    margin: 0 15px;
}

.submit {
    margin: 0 0 0 15px;
}

.filter {
    padding: 25px 0;
    border-top: 1px solid var(--color-grey-medium);
}

.heading {
    font-size: 1.5rem;
    font-weight: 800;
}
