.input {
    color: #231F20;
    border-radius: 10px;
    border-color: var(--color-grey-medium);
    background-color: var(--color-grey-light);
}

.input:hover {
    border-color: #231F20;
}

.input:focus {
    border-color: var(--color-primary);
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.input ~ label abbr,
.radio ~ label abbr,
.checkbox ~ label abbr,
.file_input ~ label abbr {
    color: inherit;
    font-weight: inherit;
    margin-left: 0;
}

.radio,
.checkbox {
    border-color: var(--color-grey);
    background-color: #FFF;
}

.checkbox {
    border-radius: 0;
}

.input:disabled,
.radio:disabled,
.checkbox:disabled {
    opacity: 0.5;
    background-color: var(--color-grey-light);
    cursor: default;
    pointer-events: none;
}

.file_input {
    color: #231F20;
    border-radius: 10px;
    border-color: var(--color-grey-medium);
    background-color: var(--color-grey-light);
    padding-right: 0;
    line-height: 40px;
    color: #231F20;
    padding-left: 16px;
    width: 100%;
    cursor: pointer;
}

.file_input::file-selector-button {
    float: right;
    color: #FFF;
    padding: 0 10px;
    margin: 0;
    border: none;
    box-shadow: inset 0 -2px 0 0 #43091E;
    background-color: var(--color-primary);
    border-radius: 10px;
    cursor: pointer;
}

.input_error {
    border: 1px solid red;
}

.file_error {
    color: #E50000;
    font-size: 1.3rem;
    line-height: 2rem;
    display: inline-flex;
}

:global(form.validated) .file_input:invalid {
    color: #DD000D;
    border: 1px solid rgb(255, 89, 92);
}