.root {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
}

.root ul {
    margin: 0;
    padding: 0;
    list-style-type:none
}

.heading {
    font-size: 1.5rem;
    font-weight: 800;
}

.link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #231F20;
    height: 27px;
}

.icon {
    margin-right: 20px;
}

.filter {
    padding: 25px 0;
    border-top: 1px solid var(--color-grey-medium);
}

.heading {
    font-size: 1.5rem;
    font-weight: 800;
}
