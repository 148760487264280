.root {
    display: flex;
    overflow: hidden;
}

.menu,
.menu_hover {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

.menu>li {
    height: 54px;
    flex-grow: 1;
    text-align: center;
}

.menu>li:first-child {
    text-align: left;
}

.menu>li:last-child {
    text-align: right;
}

.menu ul li {
    text-align: left;
}

.menu a:hover {
    text-decoration: underline;
}

.menu_hover li:hover div.sub_menu_container {
    display: flex;
}

.sub_menu_container {
    display: none;
    position: absolute;
    background-color: #ffffff;
    top: 54px;
    left: 0;
    right: 0;
    min-height: 300px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
    padding-bottom: 20px;
}

.sub_menu_container>div {
    display: flex;
}

.sub_menu_container>div>div {
    display: flex;
    flex-direction: column;
}

.sub_menu {
    display: inline-flex;
    flex-direction: column;
    font-weight: 800;
    min-width: 330px;
    margin: 10px 0px;
}

.sub_menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-weight: 800;
    line-height: 1.5rem;
    font-size: 1.2rem;
    padding: 5px 0 5px 0;
    text-align: center;
    height: 100%;
    color: #73082F;
}

.menu a.actie {
    color: rgb(255, 153, 0);
}

span.link {
    cursor: default;
}

.menu>li:first-child>.link {
    padding-left: 0px;
}

.menu>li:last-child>.link {
    padding-right: 0px;
}

.sub_link {
    display: inline-flex;
    font-size: 1.4rem;
    text-decoration: none;
}

.sub_sub_link {
    display: inline-flex;
    text-decoration: none;
    color: #231F20;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 2.3rem;
}

.highlighted {
    color: #FFA50A;
}

@media (min-width: 768px) {
    .menu>li>.link {
        padding-left: 3px;
        padding-right: 3px;
    }
}

@media (min-width: 1024px) {
    .menu>li>.link {
        padding-left: 9px;
        padding-right: 9px;
        font-size: 1.3rem;
    }
}

@media (min-width: 1440px) {
    .menu {
        padding: 0;
    }
}