.root {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    height: -webkit-fill-available;
    background: rgba(0, 0, 0, 0.5);
    z-index: 200;
    animation-name: fade_in;
    animation-duration: 0.2s;
}

.open {
    display: block;
}

.closing {
    animation-name: fade_out;
    animation-duration: 0.2s;
}

.content {
    display: flex;
    width: 100%;
    height: 100%;
    height: -webkit-fill-available;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    animation-name: move_in;
    animation-duration: 0.2s;
    animation-timing-function: ease-out;
}

@keyframes fade_in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fade_out {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes move_in {
    0% { transform: translateY(20px); }
    100% { transform: translateY(0); }
}
