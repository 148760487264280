.root {
    position: relative;
    height: 100%;
    flex: 0 0 15.60734463276836%;
    margin-right: 18px;
    scroll-snap-align: start;
}

.root:last-child {
    margin-right: 0px;
}

@media (max-width: 768px) {
    .root {
        padding-bottom: 20px;
    }
}