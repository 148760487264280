.root {
    position: relative;
    padding: 12px;
    background: #FFF;
    max-width: calc(100% - 24px);
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.55);
    overflow-x: hidden;
    overflow-y: auto;
}

.root h4 {
    display: flex;
    align-items: center;
    margin: 0 0 20px 0;
}

.radio {
    margin-right: 20px;
}

.bottom_label {
    font-size:  1.2rem;
}

.close {
    position: absolute;
    top: 24px;
    right: 24px;
}

@media (min-width: 768px) {
    .root {
        min-width: 700px;
    }
}