.pagination {
    height: 65px;
    padding: 15px 0;
    margin-top: 24px;
    border-top: 1px solid var(--color-grey-medium);
    justify-content: flex-end;
    display: flex;
}

.pagination div {
    border: 1px solid var(--color-grey-medium);
    border-radius: 5px;
    padding: 0px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    color: var(--color-primary);
    cursor: pointer;
}

.active_container {
    border: 1px solid var(--color-primary) !important;
    font-weight: bold;
}