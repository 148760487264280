.root {
    display: grid;
    grid-row-gap: 12px;
    grid-column-gap: 12px;
    grid-template-columns: repeat(2, 1fr);
}

.row {
    grid-column: 1/3;
}

@media (min-width: 768px) {
    .root {
        grid-row-gap: 4rem;
        grid-column-gap: 4rem;
        grid-template-columns: repeat(3, 1fr);
        padding: 0 3rem;
    }

    .row {
        grid-column: 1/4;
    }
}
