.root {
    margin: 20px 0;
    padding: 0 0 0 40px;
    position: relative;
}

.icon {
    position: absolute;
    left: 0;
    top: 0;
}

.coupons_wrapper {
    position: relative;
}

.coupon {
    cursor: pointer;
}

.valid {
    color: #65B32E;
    font-weight: bold;
}

.delete_icon {
    top: 3px;
    margin-left: 5px;
    position: relative;
}

.loader {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    background-color: rgba(255, 255, 255, 0.7);
}