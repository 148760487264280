.root {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    transition: height 0.08s ease-in;
    overflow: hidden;
}

.hide {
    height: 0px;
}