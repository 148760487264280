.iconlink {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    color: var(--color-grey-light);
    text-decoration: none;
    font-size: 1.3rem;
    line-height: 2rem;
    margin-right: 12px;
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
}

.iconlink:active {
    color: var(--color-grey-light);
}

.iconlink:last-child {
    margin-right: 0px;
}

.dropdown {
    position: absolute;
    top: calc(100% + 15px);
    left: -20px;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 135px;
    border-radius: 10px;
    border: 1px solid rgb(165, 165, 165);
}

.item {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    text-decoration: none;
    color: #333;
    transition: background-color 0.3s;
}

.disabled {
    pointer-events: none;
    cursor: default;
}

.hover:hover {
    color: white;
    background-color: #72082e;
}

.hover:hover a {
    color: white;
}

.flag {
    width: 20px;
    height: 15px;
    margin-right: 10px;
}

.name {
    display: none;
}

.dropdown::before,
.dropdown::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 19px;
  border: 11px solid transparent;
  border-bottom-color: #dddddd;
}

.dropdown::after {
  left: 20px;
  border: 10px solid transparent;
  border-bottom-color: #ffffff;;
}

@media (min-width: 768px) {
    .iconlink {
        margin-right: 35px;
    }
}

@media (min-width: 1024px) {
    .iconlink {
        margin-right: 22px;
    }
}

@media (min-width: 1440px) {
    .iconlink {
        margin-right: 37px;
    }

    .name {
        display: inline;
    }

    .dropdown {
        left: -10px;
        width: 150px;
    } 
}
