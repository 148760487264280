.root {
    height: 100%;
    margin-top: 20px;
}

.slider:not(.slider_no_controls) {
    height: 100%;
    padding: 0 40px;
}

.item {
    margin-right: 0;
}

@media (min-width: 768px) {
    .root {
        padding-left: 30px;
        margin-top: 0;
    }

    .slider {
        padding: 0 0 0 18px;
    }

    .item {
        padding-right: 18px;
    }
}
