.root {
    padding-left: 20px;
    margin: 1rem 0;
}

.label {
    display: flex;
    align-items: center;
}

a.label {
    text-decoration: none;
    color: initial;
}

.label:before {
    position: relative;
    content: "";
    display: inline-block;
    width: 7px;
    height: 7px;
    border-right: 0.2em solid var(--button-secondary-color);
    border-top: 0.2em solid var(--button-secondary-color);
    transform: rotate(-135deg);
    margin-right: 1rem;
}

.active {
    font-weight: 600;
}
