
.row {
    margin-bottom: 20px;
    display: flex;
    gap: 2rem;
    align-items: center;
}

.label {
    display: inline-block;
    font-weight: 800;
}

.sticky {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: var(--color-grey-light);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem 2rem;
    gap: 1.5rem;
    z-index: 9;
}

.sticky .row {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.error {
    color: #E50000;
}

.actions {
    display: flex;
    width: 100%;
}

.wishlistbutton {
    border-radius: 10px;
    padding: 10px 30px;
    height: 44px;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #000;
}

.product_page_counter {
    transition: none;
}

.hide_container {
    display: none;
}