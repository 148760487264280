.form {
    background: var(--color-grey-light);
    border-radius: 10px;
    padding: 13px 38px; 
}

.header {
    color: #73082F;
    margin: 0;
}

.input {
    background-color: #ffffff;
}