.root {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%; 
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(31, 31, 31, 0.1);
    overflow: hidden;
    z-index: 200;
    display: flex;
    flex-direction: column;
    -webkit-box-shadow: 0px -4px 3px rgba(134, 134, 134, 0.75);
    -moz-box-shadow: 0px -4px 3px rgba(184, 184, 184, 0.75);
    box-shadow: 0 -3px 3px rgba(204, 204, 204, 0.75);
}

.sticky {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
}

.unit {
    margin-bottom: 10px;
}

.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.atc {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}

.atc_label {
    display: block;
    margin-bottom: 10px;
}

.status {
    margin-top: 10px;
}

.actions {
    display: flex;
}

.wishlistbutton {
    border-radius: 10px;
    padding: 10px 30px;
    height: 44px;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #000;
}

.image {
    flex: 0 0 100%;
    max-width: 70px;
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.name {
    margin-top: 0;
    margin-bottom: 5px;
    text-transform: none;
}

.info {
    display: flex;
    gap: 5px;
}

.atc_button {
    width: 100%; 
    margin-right: 10px;
}

@media (max-width: 768px) { 
    .root {
        display: none;
    }
}

@media (max-width: 1440px) and (min-width: 769px) {
    .sticky {
        flex-direction: column;
        align-items: center;
    }

    .atc {
        order: 1;
        margin-bottom: -50px;
        align-self: flex-end;
    }

    .info {
        order: 2;
        margin-top: 10px; 
        width: auto; 
        align-self: flex-start;
    }
}