.h1 {
    color: #72082e;
    margin-bottom: 0;
}

.info {
    display: inline-flex;
    flex-direction: column;
}

.editlink {
    display: inline-flex;
    align-items: center;
    color: #FFA50A;
    text-decoration: none;
    margin-top: 10px;
}

.editlink:active {
    color: #FFA50A;
}

.editlink span {
    margin-right: 5px;
}

.left_content {
    background-color: var(--color-grey-light);
    flex: 1 1 auto;
    height: 100%;
}

.inner_content {
    padding: 2rem 3rem;
}

.right_content {
    height: 100%;
    flex: 1 1 60%;
}

.right_content img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

.newsletter_form {
    height: 100%;
    flex: 1 1 40%;
}

.newsletter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.newsletter_subscription {
    margin: 2rem 0;
}

@media screen and (max-width: 768px) {
    .newsletter {
        flex-direction: column;
    }

    .newsletter_form {
        width: 100%;
    }

    .right_content {
        width: 100%;
    }
}