.root {
    display: flex;
    color: #231F20;
    font-weight: bold;
}

.root a {
    color: inherit;
    text-decoration: none;
    margin-left: 40px;
}

.root a:hover {
    text-decoration: underline;
}

.root a:first-child {
    margin-left: 0px;
}
