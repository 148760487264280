.root {
    display: flex;
    padding: 40px 0 15px;
    border-bottom: 1px solid var(--color-grey-medium);
    overflow-x: auto;
    white-space: nowrap;
}

.anchor {
    border: 1px solid #231F20;
    border-radius: 11px;
    padding: 5px 10px;
}

.anchor:not(:last-child) {
    margin-right: 10px;
}

@media (min-width: 768px) {
    .root {
        padding: 30px 0;
        overflow-x: hidden;
    }
}