.root {
    position: relative;
}

.topmenu {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    background-color: #72082e;
    color: #fff;
}

.topmenu_container {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.logo img {
    height: 28px;
    width: auto;
}

.menu {
    display: flex;
    align-items: center;
}


@media (min-width: 768px) {

    .logo {
        position: unset;
        left: unset;
        top: unset;
        transform: unset;
    }

    .logo img {
        height: 49px;
    }

    .topmenu {
        height: 79px;
    }

}
