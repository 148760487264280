.root {
    display: flex;
    align-items: center;
}

.divider {
    margin: 0 10px;
}

.link {
    text-decoration: none;
    color: #231F20;
    white-space: nowrap;
    overflow: hidden;
}

.link:last-child {
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    .root {
        flex-wrap: wrap;
        margin-top: 10px;
        gap: 5px;
    }
    .divider {
        margin: 0;
    }
}