.root {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #FFFFFF;
    border: 1px solid #000000;
    padding: 20px;
    height: 100%;
}

.score {
    display: flex;
    align-items: center;
    gap: 10px;
}

.score_label {
    white-space: nowrap;
}

.description {
    flex: 1;
}

.description .text {
    max-height: 60px;
    overflow: hidden;
}

.description .text.text_full {
    max-height: none;
}

.show_more {
    color: var(--color-primary);
    cursor: pointer;
}

.info {
    color: var(--color-grey);
    font-size: 1.2rem;
}

.info > span:not(:last-child)::after {
    content: '|';
    padding: 0 5px;
}
