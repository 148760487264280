.root {
    position: relative;
    display: flex;
    padding: 20px 0;
    border-top: 1px solid var(--color-grey-medium);
}

.root:first-child {
    border-top: none;
}

.image {
    flex: 0 0 80px;
    width: 80px;
    height: 80px;
    background-color: #f0f0f0;
    margin-right: 12px;
}

.details {
    display: flex;
    flex-direction: column;
    flex: 0 1 100%;
}

.name {
    color: #73082F;
    font-size: 1.4rem;
    font-weight: 800;
    margin-bottom: 10px;
}

.price {
    flex: 0 0 80px;
    text-align: right;
    font-size: 1.4rem;
    font-weight: 800;
}

.quantity {
    display: flex;
    width: 100%;
}
