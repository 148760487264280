.root {
    height: 100%;
    padding: 12px;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior-y: contain;
}

.header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    color: #fff;
    background-color: #72082e;
    font-family: 'Hind', sans-serif;
    font-size: 1.6rem;;
    font-weight: 700;
    text-transform: uppercase;
}

.header .close {
    position: absolute;
    right: 10px
}

.footer {
    padding: 15px 12px;
    color: #fff;
    background-color: #72082e;
}

.footer button {
    width: 100%;
}

.heading {
    font-size: 1.5rem;
    font-weight: 800;
}

.heading:first-letter {
    text-transform: capitalize;
}

.filter {
    padding: 25px 0;
    border-top: 1px solid var(--color-grey-medium);
}

@media (min-width: 768px) {
    .root {
        padding: 0;
    }
}
