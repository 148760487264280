:root {
    /* general */
    --color-primary: #73082F;
    --color-secondary: #FFF;

    --color-grey: #707070;
    --color-grey-medium: #B5B5B5;
    --color-grey-light: #F9F9F6;

    /* components */
    --button-primary-color: #FFF;
    --button-primary-background: var(--color-primary);
    --button-secondary-color: #231F20;
    --button-secondary-background: var(--color-secondary);

    --loader-color: var(--color-primary);

    --counter-color: #FFF;
    --counter-background: var(--color-primary);

    /* TrustPilot colors */
    --trustpilot-one-star: #FF3722;
    --trustpilot-two-star: #FF8621;
    --trustpilot-three-star: #FFCE03;
    --trustpilot-four-star: #73CF11;
    --trustpilot-five-star: #00B67A;

}
