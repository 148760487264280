.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.next {
    margin-top: 30px;
}

.add_address {
    display: flex;
    align-items: center; 
}

.add_address button {
    display: flex;
    margin-left: 10px;
    padding: 7px 20px;
    align-items: center;
    border: 1px solid var(--color-grey);
    background-color: #ffffff;
    border-radius: 10px;
    color: #73082F;
    cursor: pointer;
}

.add_icon {
    margin-right: 10px;
}