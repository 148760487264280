.root {
    background-color: var(--color-grey-light);
    padding: 25px 12px 15px 12px;
    margin-left: -12px;
    margin-right: -12px;
    max-width: 700px;
    min-height: 500px;
}

.root h2 { margin-top: 0px; }

.feature { margin-bottom: 30px; }
.feature:last-child { margin-bottom: 0px; }

.feature h4 { margin-bottom: 5px; }

.line { 
    display: flex;
    width: 100% ;
    border-bottom: solid 1px #d5d5d3;
    padding: 7px 0;
}

.line strong { 
    flex: 0 0 160px;
    margin-right: 5px;
    word-break: break-word;
}

@media (min-width: 768px) {
    .root {
        padding: 25px 15px 15px 15px;
        margin-left: 0;
        margin-right: 0;
    }

    .line strong { 
        flex: 0 0 220px;
    }
}

@media (min-width: 1440px) {
    .root {
        padding: 25px 60px 15px 15px;
    }
}