.modal {
    justify-content: start;
    margin-top: 16vh;
}

.root {
    background: #FFF;
    max-width: calc(100% - 24px);
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.55);
    border-radius: 30px;
    padding: 35px 100px;
    width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    color: var(--color-primary);
    margin-bottom: 30px;
    text-align: center;
    font-size: 2.8rem;
}

.stay_on_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
}

.flag {
    width: 24px;
    height: 16px;
    margin-right: 10px;
}

.stay_on_text {
    text-decoration: underline;
    padding: 0;
    margin: 0;
    color: var(--color-primary);
}

.button {
    font-weight: bold;
}

