.form {
    background: var(--color-grey-light);
    border-radius: 10px;
    padding: 13px 38px; 
    margin-bottom: 25px;
}

.header {
    color: #73082F;
    margin: 0;
}

.input {
    background-color: #FFFFFF;
}

.button {
    position: relative;
    border-radius: 10px;
    padding: 10px 30px;
    height: 44px;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0;
    text-align: center;
    text-decoration: none;
    border: 1px solid transparent;
    cursor: pointer;
    vertical-align: middle;
    color: #FFF;
    border: none;
    box-shadow: inset 0 -2px 0 0 #599131;
    background-color: #65B32E;
    font-weight: 800;
}

.button:hover {
    background-color: #599131;
}

.button:active {
    color: #FFF;
}

.button:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
}

.button .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.label {
    display: inline-flex;
    opacity: 0;
}