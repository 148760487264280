.root {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #FFFFFF;
    z-index: 1000;
}

.thumbnails_zoom {
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1001;
    display: flex;
    justify-content: start;
    flex-direction: column;
    gap: 1rem;
    width: 10rem;
    position: fixed;
    transition: transform 0.3s ease-in-out;

}

.inner_zoom_thumbnails {
    width: 10.5rem;
    height: auto;
    cursor: pointer;
}


.tools {
    display: flex;
    flex-direction: column;
    background: transparent;
    opacity: 0.7;
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
}

.close {
    background:
    linear-gradient(#000,#000),
    linear-gradient(#000,#000);
    background-position:center;
    background-size: 50% 1px,1px 50%;
    background-repeat:no-repeat;
    display: inline-block;
    width: 50px !important;
    height: 50px;
    transform: rotate(45deg);
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 999;
    cursor: pointer;
}

.plus {
    background:
    linear-gradient(#000,#000),
    linear-gradient(#000,#000);
    background-position:center;
    background-size: 50% 1px,1px 50%;
    background-repeat:no-repeat;
    display: inline-block;
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.min {
    background:
    linear-gradient(#000,#000),
    linear-gradient(#000,#000);
    background-position:center;
    background-size: 50% 1px,0 50%;
    background-repeat:no-repeat;
    display: inline-block;
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.inner_zoom_thumbnails {
    position: relative;
    transition: border-color 0.3s, border-width 0.3s;
}

.inner_zoom_thumbnails.active {
    border-bottom-color: black;
}

.inner_zoom_thumbnails::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 0;
    bottom: -5px;
    background-color: black;
    transition: width 0.3s;
    z-index: 1;
}

.inner_zoom_thumbnails.active::before {
    width: 100%; 
}

.button {
    position: absolute;
    margin: 0;
    border: none;
    background: none;
    cursor: pointer;
    top: 50%;
    transform: translate(-0%, -50%);
    z-index: 1001;
    opacity: 0.5;
}

.button:hover {
    opacity: 1;
}

.prev {
    left: 20%;
}

.next {
    right: 20%;
}

.button_thumbs {
    position: absolute;
    margin: 0;
    border: none;
    background: none;
    cursor: pointer;
    top: 50%;
    left: 3rem;
    transform: translate(0, -50%);
    z-index: 1001;
    transition: all 0.3s ease-in-out;
    opacity: 0.5;
}

.button_thumbs:hover {
    opacity: 1;
}

.next_thumbs {
    top: 100%;
    bottom: 2rem;
    transition: top 0.3s ease-in-out;

}
.prev_thumbs {
    top: -2rem;
    transition: top 0.3s ease-in-out;

}

.root img  {
    max-width: 120rem;
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    display: block;
}

.transform_wrapper {
    display: flex;
    align-items: center;
}

.image {
    cursor: pointer;
    position: relative;
}

.youtube_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background: rgba(255, 255, 255, 0.4);
}

.video_image {
    display: block;
}

.video_image.video_active {
    display: none;
}

.video {
    display: none;
    width: 900px;
}

.video_active+.video {
    display: block;
}

.bbw {
    width: 900px;
    height: 100vh;
}

@media (max-width: 1441px) {
    .root img {
        width: 100%;
        max-width: 84rem;
        height: 100vh;
    }

    .video,
    .bbw {
        width: 700px;
    }
}

@media (max-width: 768px) {
    .root img {
        width: 100%;
        height: 125rem;
        display: block; 
        max-width: 50rem; 
        height: auto; 
        margin: 0 auto;
    }

    .video,
    .bbw {
        width: 500px;
    }

    .thumbnails_zoom {
        display: flex;
        justify-content: start;
        flex-direction: row;
        transform: none;
        gap: 5px;
        bottom: 0;
        left: 0;
        background-color: #FFFFFF;
        top: unset;
        height: auto;
        width: 100%;
        padding: 1rem;
    }

    .prev {
        left: 0;
    }
    
    .next {
        right: 0;
    }
}

@media (max-width: 640px) {

    .video,
    .bbw {
        width: 380px;
    }
}