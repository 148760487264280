.button {
    color: var(--color-primary);
    cursor: pointer;
}

.col {
    padding-top: 0px;
    padding-bottom: 0px;
}

.input {
    border: 1px solid var(--color-grey);
}

.input:disabled {
    background-color: #ededed;
    color:  var(--color-grey);
}

.success_message {
    background-color: var(--color-grey-light); 
    padding: 15px;
    border-radius: 10px;
    color: rgb(108, 109, 76);
}

.save_password_btn {
    background-color: transparent;
    border: none;
    padding: 0;
    color: var(--color-primary);
    border-radius: 0;
    box-shadow: none;
    font-weight: normal;
    cursor: pointer;
}

.save_password_btn:hover,
.save_password_btn:focus,
.save_password_btn:active {
    background-color: transparent; 
    box-shadow: none;
    color: var(--color-primary);
}

.error_message {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    background-color: #FFE5E5;
    border: solid 1px #E50000;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.password_form {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 35px;
}

.password_requirements {
    display: flex;
    flex-direction: column;
    margin: 15px 0 10px;
    padding: 10px !important; 
}

.divider {
    height: 10px;
}