.root {
    position: relative;
    width: 100%;
}

.progress {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 9px;
    margin-top: 40px;
    color: #eeeeec;
}

.progress::-moz-progress-bar {
    background: #FFA50A;
    border-radius: 10px;
}

.progress::-webkit-progress-value {
    background: #FFA50A;
    transition: width 1s;
    border-radius: 10px;
}

.progress::-webkit-progress-bar {
    background: #eeeeec;
}

.steps {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
}

.step {
    width: 23px;
    height: 23px;
    margin-left: -2px;
    top: -2px;
    border-radius: 50%;
    position: absolute;
    background: var(--color-grey-medium);
    background-image: linear-gradient(#FFA50A, #FFA50A);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 0% 0%;
    transition: background-size 1s;
}

.step span {
    position: absolute;
    top: -30px;
    left: -27px;
    width: 80px;
    text-align: center;
}

.step.active {
    -webkit-box-shadow: inset 0px 0px 0px 3px #FFA50A;
    -moz-box-shadow: inset 0px 0px 0px 3px #FFA50A;
    box-shadow: inset 0px 0px 0px 3px #FFA50A;
    background: transparent;
    background-image: linear-gradient(#FFA50A, #FFA50A);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 0% 0%;
    transition: background-size 1s;
}

.step.active span {
    font-weight: bold;
}

.step.active:after {
    content: '';
    width: 11px;
    height: 11px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    background: #FFA50A;
}

.step.complete {
    /* background: #FFA50A; */
    background-size: 100% 100%;
}

.step.complete:after {
    content: '';
    width: 11px;
    height: 11px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14.558" height="11.156" viewBox="0 0 14.558 11.156"><path id="check" d="M18.058,6.763,8.075,16.746,3.5,12.17,4.673,11l3.4,3.394,8.81-8.8Z" transform="translate(-3.5 -5.59)" fill="white"/></svg>');
    background-position: right 1px center;
    background-repeat: no-repeat;
    background-size: 10px 8px;
}