.root {
    position: relative;
    display: flex;
    padding: 20px 0;
    border-top: 1px solid var(--color-grey-medium);
}

.root:first-child {
    border-top: none;
}

.product_link {
    text-decoration: none;
}

.image {
    flex: 0 0 80px;
    width: 80px;
    height: 80px;
    background-color: #f0f0f0;
    margin-right: 12px;
}

.details {
    display: flex;
    flex-direction: column;
    flex: 0 1 100%;
}

.name {
    color: #73082F;
    font-size: 1.4rem;
    font-weight: 800;
    margin-bottom: 10px;
}

.unit {
    margin-bottom: 10px;
}

.unit_label { margin-right: 10px; }

.price {
    text-align: right;
    font-size: 1.3rem;
    font-weight: 800;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.price .totalPriceWithoutCouponDiscount {
    position: relative;
    color: #b5b5b5;
    text-decoration:line-through;
    font-weight: 500;
}

.remove_quantity_delivery {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.remove {
    width: 100%;
}

.quantity {
    width: 100%;
}

.error {
    color: #E50000;
    margin: 1rem 0;
}

.remove {
    display: flex;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    margin-bottom: 15px;
}

.remove_container {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
}

.delivery {
    margin-top: 15px;
    width: 100%;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    z-index: 10;
}

.valid_coupon {
    font-weight: bold;
    color: #65B32E;
    margin-top: 10px;
}

@media (min-width: 768px) {
    .root {
        padding: 30px 30px 30px 0;
    }

    .remove_quantity_delivery {
        flex-direction: row;
        align-items: center;
    }

    .remove_container {
        margin-right: 24px;
        width: auto;
    }

    .remove {
        margin-bottom: 0px;
    }

    .quantity {
        width: auto;
    }

    .delivery {
        margin-top: 0px;
        margin-left: 48px;
        width: auto;
    }

    .image {
        flex: 0 0 120px;
        width: 120px;
        height: 120px;
        margin-right: 30px;
    }
}