.root {
    border-radius: 10px;
    padding: 10px 30px;
    height: 44px;
    white-space: nowrap;
}



.inline-icon {
    margin-left: 10px;
}

.icon {
    padding: 10px 13px;
}

.primary {
    color: #FFF;
    border: none;
    box-shadow: inset 0 -2px 0 0 #43091E;
    background-color: var(--color-primary);
}
.primary:hover {
    background-color: #43091E;
}

.primary:active {
    color: #FFF;
}

.secondary {
    color: #FFF;
    border: none;
    box-shadow: inset 0 -2px 0 0 #599131;
    background-color: #65B32E;
    font-weight: 800;
}

.secondary:hover {
    background-color: #599131;
}

.secondary:active {
    color: #FFF;
}

.outline {
    color: #231F20;
    border-color: #231F20;
    background-color: #FFF;
}
.outline:hover {
    color: #FFF;
    border-color: transparent;
    background-color: var(--color-primary);
}

.link {
    text-decoration: none;
}
.link:hover {
    text-decoration: underline;
}

.atc {
    color: #FFF;
    border: none;
    box-shadow: inset 0 -2px 0 0 #599131;
    background-color: #65B32E;
    font-weight: 800;
    text-transform: uppercase;
}
.atc:hover {
    background-color: #599131;
}
