.wrapper {
    width: 100%;
    margin-right: 10px;
}

.button {
    border-radius: 10px;
    padding: 10px 13px;
    color: #FFF;
    border: none;
    background-color: var(--color-primary);
    font-weight: 800;
    text-transform: uppercase;
    height: 44px;
    float: right;
}

.big {
    width: 100%;
    padding: 10px 30px;
}

.icon {
    margin-left: 10px;
}

.submit {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    right: 0;
}

.submit_button {
    border-radius: 10px;
    padding: 10px;
    color: #FFF;
    border: none;
    background-color: var(--color-primary);
    font-weight: 800;
    text-transform: uppercase;
    height: 44px;
}

.inputs {
    position: absolute;
}

.input {
    height: 44px;
}

.form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-top: 22px;
    padding-bottom: 20px;
}

.hidden_fields {
    display: none;
}

.error {
    color: #FF595C;
    margin: 0;
    margin-top: 5px;
}

@media (max-width: 768px) {
    .inputs {
        position: relative;
    }

    .submit {
        width: 20%;
    }

    .form {
        width: 120%;
    }
}