.root {
    display: flex;
    flex-direction: column;
}

.heading {
    font-size: 1.5rem;
    font-weight: 800;
}

.values {
    margin: 15px 0 0 0;
    padding: 0;
    list-style: none;
}

.link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    padding: 4px 0;
}

.checkbox {
    margin-right: 20px;
    pointer-events: none;
}

.value {
    font-size: 1.3rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.count {
    margin-left: 5px;
}

.filter {
    padding: 25px 0;
    border-top: 1px solid var(--color-grey-medium);
}

.heading {
    font-size: 1.5rem;
    font-weight: 800;
}