.root {
    margin-bottom: 24px;
}

.item {
    min-width: 300px;
    height: 100%;
    padding-bottom: 10px;
}

.address {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 20px;
    border: 1px solid var(--color-grey);
    background-color: #ffffff;
    border-radius: 10px;
    text-align: left;
    margin-right: 20px;
    cursor: pointer;
}

.address_selected {
    border: 1px solid #73082F;
    background-color: #f5edf0;
}

.address:last-child {
    margin-right: 0px;
}

.close_icon {
    position: absolute;
    right: 10px;
    top: 10px;
    color: var(--color-grey);
}
