.root {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 343px;
    color: #231F20;
    border-radius: 10px;
    border-color: transparent;
    background-color: var(--color-grey-light);
    border: 1px var(--color-grey-medium) solid;
    margin: 0;
    padding: 0px 0px 0px 15px;
    background: #ffffff;
}

.root input {
    box-shadow: none;
    appearance: none;
    outline: none;
    width: 100%;
    border: none;
    height: 36px;
    margin-right: 15px;
}

.root button {
    height: 36px;
    border-radius: 10px;
    border: none;
    border-left: solid 1px var(--color-grey-medium);
    color: #231F20;
    font-weight: 800;
    text-transform: uppercase;
    padding: 0 17px;
    cursor: pointer;
    background: var(--color-grey-light);
}