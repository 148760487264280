.root {
    position: relative;
    display: flex;
}

.slides {
    display: flex; 
    overflow-x: auto; 
    overflow-y: hidden; 
    width: 100%;
    scroll-snap-type: x mandatory; 
    -webkit-overflow-scrolling: touch; 
    scroll-behavior: smooth; 
}

.show-1 > div {
    max-width: 100%;
    flex: 0 0 100%;
}

.show-2 > div {
    max-width: 50%;
    flex: 0 0 50%;
}

.show-3 > div {
    max-width: 33.333333%;
    flex: 0 0 33.333333%;
}

.show-4 > div {
    max-width: 25%;
    flex: 0 0 25%;
}

.show-5 > div {
    max-width: 20%;
    flex: 0 0 20%;
}

.show-6 > div {
    max-width: 16.666666%;
    flex: 0 0 16.666666%;
}

.noscrollbar {
    overflow-x: hidden;
}

.prev,
.next { 
    position: absolute; 
    top: 0;  
    z-index: 10;

    top: 50%;
    transform: translate(0, -50%);
}

.prev { left: 0; }
.next { right: 0; }

@media (max-width: 767px) {
    .show-mobile-1 > div {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .show-mobile-2 > div {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .show-mobile-3 > div {
        max-width: 33.333333%;
        flex: 0 0 33.333333%;
    }

    .show-mobile-4 > div {
        max-width: 25%;
        flex: 0 0 25%;
    }

    .show-mobile-5 > div {
        max-width: 20%;
        flex: 0 0 20%;
    }

    .show-mobile-6 > div {
        max-width: 16.666666%;
        flex: 0 0 16.666666%;
    }
}

@media (min-width: 768px) {
    
    .slides { scrollbar-color: var(--color-primary) #E5E5E5; scrollbar-width: thin; }
    .slides::-webkit-scrollbar { height: 6px; }
    .slides::-webkit-scrollbar-track { background: #E5E5E5; border-radius: 3px; }
    .slides::-webkit-scrollbar-thumb { background: var(--color-primary); border-radius: 3px; border: 1px solid #ffffff; }

}
