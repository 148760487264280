.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 20px 0;
    border-bottom: solid 1px var(--color-grey-medium);
}

.title {
    font-weight: bold;
}

.root>*:not(:last-child) {
    margin-bottom: 10px;
}