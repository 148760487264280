.root {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: solid 1px var(--color-grey-medium);
}

.menu {
    margin-left: 40px;
}
