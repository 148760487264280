.root { 
    position: absolute;
    top: 15px;
    left: 15px;
    margin: 0;
    padding: 0;
    list-style: none;
    z-index: 10;
}

.root > li {
    margin-bottom: 4px;
}

.root > li:last-child {
    margin-bottom: 0;
}

.label {
    display: inline-block;
    padding: 0 10px;
    border-radius: 6px;
    font-weight: 800;
    line-height: 24px;
}

.img_label > svg {
    height: 24px;
}

.promotion {
    color: #fff;
    background-color: #e3002b;
}
