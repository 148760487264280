.root {
    position: relative;
    display: flex;
    padding: 20px 0;
    border-top: 1px solid var(--color-grey-medium);
}

.image {
    flex: 0 0 80px;
    width: 80px;
    height: 80px;
    background-color: #f0f0f0;
    margin-right: 12px;
}

.details {
    display: flex;
    flex-direction: column;
    flex: 0 1 100%;
}

.name {
    color: #73082F;
    font-weight: 800;
    margin-bottom: 10px;
}

.error {
    color: #E50000;
    margin: 1rem 0;
}

.price {
    text-align: right;
    font-size: 1.4rem;
    font-weight: 800;
}

.quantity {
    width: 100%;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    z-index: 10;
}

@media (min-width: 768px) {
    .root {
        padding: 30px 0;
    }

    .image {
        margin-right: 30px;
    }
}
