.root {
    display: flex;
    width: 100px;
}

.root > svg {
    width: 100%;
    height: auto;
}

.score_0 {
}

.score_1,
.score_1_5 {
    color: var(--trustpilot-one-star);
}

.score_2,
.score_2_5 {
    color: var(--trustpilot-two-star);
}

.score_3,
.score_3_5 {
    color: var(--trustpilot-three-star);
}

.score_4 {
    color: var(--trustpilot-four-star);
}

.score_4_5,
.score_5 {
    color: var(--trustpilot-five-star);
}
