.item {
    min-width: 260px;
}

.next,
.prev {
    border: 1px solid;
    padding: 1rem;
    background: var(--color-white);
}

@media (min-width: 640px) {
    .item {
        flex: 0 0 15.60734463276836%;
        min-width: 220px;
    }
}

@media (max-width: 769px) {
    .prev,
    .next {
        transform: translateX(0);
    }

    .slider {
        padding: 0 25px;
    }
}

@media (min-width: 1024px) {
   
}

@media (min-width: 1440px) {
    .prev {
        transform: translatex(-3rem);
    }

    .next {
        transform: translateX(3rem);
    }
}