.root {
    background-color: var(--color-grey-light);
    padding: 25px 0 35px 0;
    margin-bottom: 30px;
}

.average {
    margin-bottom: 30px;
}

.summary {
    
}

.reviews {
    
}

@media (min-width: 768px) {
    .average {
        margin-bottom: 20px;
    }
}
