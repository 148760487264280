
.nav {
    display: flex;
    flex-direction: column;
    min-height: 180px;
    padding: 27px;
    background-color: var(--color-grey-light); 
}

.nav a {
    display: inline-flex;
    color:#231F20;
    margin-bottom: 10px;
    text-decoration: none;
}

.nav a:last-child {
    margin-bottom: 0px;
}

.nav a:global(.active) {
    
    font-weight: 800;
    text-decoration: underline;
}

.nav button {
    text-align: left;
    border: none;
    padding: 0;
    background: none;
    cursor: pointer;
}
