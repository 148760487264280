.root {
    background: var(--color-grey-light);
    border-radius: 10px;
    padding: 13px 38px;
    text-align: center;
    margin-bottom: 10px;
}

.header {
    color: #73082F;
    margin: 0 0 10px 0;
}

.button {
    background-color: white;
    border-radius: 5px;
    flex-grow: 1;
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px;
}

.button:hover {
    cursor: pointer;
}

.button.active {
    color: #73082F;
}

.button:hover,
.button.active {
    box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16);
}
