.root {
    position: sticky;
    width: 100%;
    bottom: 0;
    z-index: 510;
    padding: 30px 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: #fff;
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.3);
}

.title_wrapper {
    display: flex;
    align-items: center;
}

.title {
    color: var(--color-primary);
    margin: 0 0 0 12px;
}

.root p:last-child {
    margin: 0;
}

.root p span {
    cursor: pointer;
    color: #FFA50A;
}

.root p span:hover {
    text-decoration: underline;
}

.cookie_container {
    justify-content: space-between;
    align-items: center;
}

.text {
    padding-right: 100px;
}

.text div:first-child {
    margin-bottom: 8px;
}

.buttons_wrapper {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.buttons_wrapper button:first-child {
    margin-right: 15px;
}

.checkbox_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.checkbox {
    margin-bottom: 20px;
}

.showtextlink {
    margin-left: 5px;
}

@media (max-width: 768px) {
    .text {
        padding: 0;
    }

    .buttons_wrapper {
        margin-top: 15px;
        justify-content: center;
    }

}

@media (min-width: 768px) {
  
    .checkbox_wrapper {
        margin-top: 0px;
        flex-direction: row;
    }

    .buttons_wrapper {
        margin-top: 0px;
        justify-content: center;
    }

    .checkbox {
        margin-right: 20px;
        margin-bottom: 0px;
    }
}