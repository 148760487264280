.root {
    display: flex;
    width: 168px;
    height: 79px;
    padding-top: 10px;
    margin-left: 25px;
    z-index: 160;
}

.button {
    display: flex;
    justify-content: center;
    font-weight: 800;
    cursor: pointer;
    padding: 15px 30px 10px 30px;
}

.label {
    display: inline-block;
    pointer-events: none;
}

.label::after {
    content: url("data:image/svg+xml; utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2210.264%22%20height%3D%226.338%22%20viewBox%3D%220%200%2010.264%206.338%22%3E%3Cpath%20d%3D%22M6.338%2C1.206%2C5.132%2C0%2C0%2C5.132l5.132%2C5.132L6.338%2C9.058%2C2.421%2C5.132Z%22%20transform%3D%22translate%280%206.338%29%20rotate%28-90%29%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E%0A%0A");
    margin-left: 10px;
}

.open .label::after {
    content: url("data:image/svg+xml; utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2210.264%22%20height%3D%226.338%22%20viewBox%3D%220%200%2010.264%206.338%22%3E%3Cpath%20d%3D%22M6.338%2C1.206%2C5.132%2C0%2C0%2C5.132l5.132%2C5.132L6.338%2C9.058%2C2.421%2C5.132Z%22%20transform%3D%22translate%2810.264%29%20rotate%2890%29%22%20fill%3D%22%23231f20%22%2F%3E%3C%2Fsvg%3E%0A");
    margin-left: 10px;
}

.icon {
    margin-top: 25px;
}

.open {
    background-color: var(--color-grey-light);
    color: #231F20;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
}

.menu_container {
    display: block;
    position: absolute;
    top: 79px;
    right: 0px;
    left: 0px;

    overflow: hidden;
    padding-bottom: 10px;
}

.menu {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 522px;
    z-index: 150;
    background-color: #FFF;
}

.menu a:hover {
    text-decoration: underline;
}

.left_menu {
    display: flex;
    width: 322px;
    list-style: none;
    background-color: var(--color-grey-light);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
    z-index: 152;
}

.left_menu>ul {
    display: inline-flex;
    flex-direction: column;
    margin: 10px 0 0 0;
    padding: 0;
}

.left_menu ul:not(:hover)>li .submenu {
    display: flex;
}

.left_menu>ul li {
    display: inline-flex;
    width: 322px;
}

.left_menu>ul>li:last-child {
    flex-grow: 1;
}

.left_menu>ul>li:last-child .link {
    height: 40px;
}

.left_menu>ul li:hover .submenu {
    display: flex;
}

.left_menu>ul li .link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 20px;
    text-decoration: none;
    color: #231F20;
    font-size: 1.4rem;
    line-height: 1.9rem;
    border-bottom: 1px var(--color-grey-light) solid;
    overflow: auto;
}

.left_menu>ul li:hover .link {
    background-color: #FFFFFF;
    border-bottom: 1px var(--color-grey-medium) solid;
}

.left_menu ul li>.link::after {
    content: url("data:image/svg+xml; utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224.922%22%20height%3D%227.971%22%20viewBox%3D%220%200%204.922%207.971%22%3E%3Cpath%20d%3D%22M4.922.937%2C3.986%2C0%2C0%2C3.986%2C3.986%2C7.971l.937-.937L1.88%2C3.986Z%22%20transform%3D%22translate%284.922%207.971%29%20rotate%28180%29%22%20fill%3D%22%23231f20%22%2F%3E%3C%2Fsvg%3E%0A");
    display: flex;
    width: 5px;
    height: 100%;
}

.submenu {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 322px;
    background-color: #FFF;
    padding: 20px 30px 30px 30px;
    z-index: 151;
    overflow: hidden;
}

.submenu ul {
    list-style: none;
    margin: 10px 0 0 0;
    padding: 0;
}

.submenu a {
    display: inline-flex;
    font-size: 1.4rem;
    text-decoration: none;
}

.submenu ul a {
    display: inline-flex;
    text-decoration: none;
    color: #231F20;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 2.3rem;
}

.menu_column {
    display: block;
}

.menu_column ul {
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .root {
        width: 155px;
    }

    .button {
        padding: 15px 15px 10px 15px;
    }

}

@media (min-width: 1024px) {
    .root {
        width: 167px;
    }

    .button {
        padding: 15px 30px 10px 30px;
    }

    .menu {
        z-index: 150;
        width: 100%;
        max-width: 1440px;
        margin-right: auto;
        margin-left: auto;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
    }

    .menu_column {
        width: 50%;
    }


}

@media (min-width: 1440px) {

    .menu_column {
        width: 33.33333333%;
    }

}