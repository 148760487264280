.drawer {
    max-width: 460px;
    cursor: auto;
}

.header {
    position: relative;
    display: flex;
    align-items: center;
    flex: 0 0 44px;
    padding: 0 12px;
    color: #fff;
    background-color: #72082e;
    font-family: 'Hind', sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
}

.header .close {
    position: absolute;
    right: 10px;
}

.items {
    height: 100%;
    padding: 0 12px;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior-y: contain;
}

.empty {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: var(--color-grey-medium);
}

.footer {
    position: relative;
    padding: 12px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.55);
}

.buttons {
    display: flex;
    flex-direction: column;
}

.buttons button {
    width: 100%;
}

@media (min-width: 768px) {
    .header {
        flex: 0 0 79px;
        padding: 0 30px;
        color: #231F20;
        background-color: transparent;
    }

    .header .close {
        right: 25px;
    }

    .items {
        padding: 0 30px;
    }

    .footer {
        padding: 30px;
    }

    .buttons {
        flex-direction: row;
        margin-top: 15px;
    }
    
    .buttons button {
        width: 50%;
    }
}
