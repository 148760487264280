.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.next {
    margin-top: 30px;
}

.message_title {
    font-weight: 600;
    color: #FFA50A;
}

.error {
    margin-bottom: 20px;
}