.root {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    background-color: #FFE5E5;
    border: solid 1px #E50000;
    padding: 20px;
    border-radius: 10px;
}

.root span {
    margin-bottom: 10px;
}

.root span:last-child {
    margin-bottom: 0px;
}