.root { 
    position: relative;
    min-width: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #ededed;
    padding: 2rem;
}

.root strong { 
    display: inline-block;
    color: var(--color-primary);
    font-size: 1.5rem;
    height: 46px;
    max-width: 100%;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 2; 
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
}

.image img {
    transform: none;
    left: 0;
    top: 0;
}

.image {
    margin: 5%;
}

.link {
    display: block;
    text-decoration: none;
    color: inherit;
}

.unit_description {
    margin-top: 5px;
    text-transform: lowercase;
}
.unit_description::first-letter {
    text-transform: capitalize;
}

.prices {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 5px;
    max-width: calc(100% - 56px);
}

.price {
    font-size: 1.8rem;
    font-weight: 800;
    margin-right: 20px;
}

.price_from_wrapper {
    display: inline-block;
}

.price_from_label {
    margin-right: 7px;
}

.price_from {
    font-size: 1.5rem;
    text-decoration: line-through;
}

.in_stock, .out_stock {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.in_stock span, .out_stock span {
    margin-right: 5px;
}

.in_stock {
    color: #65B32E;
    font-weight: 800;
}

.out_stock {
    color: var(--color-grey);
}

.delivery_time {
    display: inline-flex;
    align-items: center;
    margin-top: 10px;
    color: #65B32E;
    font-weight: 800;
}

.delivery_time span {
    margin-right: 5px;
}

.atc {
    margin-top: 10px;
}

.back_in_stock {
    margin-top: 5px;
}

.skeleton {
    padding: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-bottom: 150%;
    background: var(--color-grey-light);
}

.wishlistbutton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    z-index: 1;
    border-radius: 50%;
    height: 52px;
    background: white;
}

.reviews {
    flex-grow: 1;
}

.review_stars {
    padding-top: 5px;
}

@media (max-width: 768px) {
    .prices {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (min-width: 768px) {
    .atc {
        bottom: 2rem;
        position: absolute;
        right: 2rem;
    }
}

@media (min-width: 1024px) {
    
    .skeleton {
        padding-bottom: 129.0909090909091%;
    }
}
